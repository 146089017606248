@use "bootstrap" as bootstrap;

.content-wrapper {
  overflow: hidden;
}


@include bootstrap.respond-below(lg) {
  table tr {
    td, th {
      white-space: nowrap;
    }
  }
}

